const Footer: React.FC = () => {
  return (
    <footer className="w-full bg-transparent">
      {/*  Divider Line */}
      <div className="w-full h-px bg-grey-600" />

      <div className="w-full px-6 py-8">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
          {/* SEO & Accessibility Optimized Copyright */}
          <span className="text-grey-300 mb-4 md:mb-0">
            © {new Date().getFullYear()} Learning Cortex
          </span>

          {/*  Footer Navigation */}
          <nav className="flex flex-col md:flex-row">
            {/* <a
              className="ml-0 md:ml-4 text-grey-300 hover:underline hover:text-sky-500"
              href="/contactus"
              aria-label="Contact Learning Cortex Support"
            >
              Contact Us
            </a> */}
            <a
              className="ml-0 md:ml-4 text-grey-300 hover:underline hover:text-sky-500"
              href="/privacypolicy"
              aria-label="View Learning Cortex's Privacy Policy"
            >
              Privacy Policy
            </a>
            {/* <a
              className="ml-0 md:ml-4 text-grey-300 hover:underline hover:text-sky-500"
              href="/terms"
              aria-label="View Learning Cortex's Terms of Service"
            >
              Terms of Service
            </a> */}
          </nav>
        </div>
      </div>

      {/* Structured Data for Google Rich Snippets */}
      <div className="hidden">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Learning Cortex",
              "url": "https://www.learningcortex.ai",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.learningcortex.ai/search?q={search_term}",
                "query-input": "required name=search_term",
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer service",
                "url": "https://www.learningcortex.ai/contactus",
              },
            }),
          }}
        />
      </div>
    </footer>
  );
};

export default Footer;
