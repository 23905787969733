'use client'

import { Button } from '@/components/ui'
import { PlaySolid } from 'iconoir-react'
import { AnimatePresence, motion } from 'motion/react'
import Link from 'next/link'
import { useRef, useState } from 'react'
import { childVariants } from '../_lib/animations'
import VideoModal from './VideoModal'

export default function Hero() {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handlePlayButtonClick = () => {
    setIsModalOpen(true)
    videoRef.current?.pause()
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <section className="flex flex-col items-center justify-center pt-8 md:flex-row md:items-center md:pb-8 md:pt-24">
      {/* Text Section */}
      <div className="flex flex-col text-left md:w-1/2 md:pr-8">
        <motion.h1
          className="bg-gradient-to-b from-sky-400 via-sky-400 to-violet-500 bg-clip-text text-left text-3xl font-extrabold text-transparent md:text-3xl"
          variants={childVariants}
        >
          Your AI Study Assistant, Personalized for You.
        </motion.h1>

        {/* Supporting Description */}
        <motion.h2
          className="mt-4 text-lg font-semibold leading-loose text-grey-200 md:mt-8"
          variants={childVariants}
        >
          Drowning in lecture slides, endless PDFs, and last-minute cramming?
          Learning Cortex simplifies studying with AI-powered organization, real-time
          feedback, and personalized study plans—so you can stop stressing and
          start learning smarter.
        </motion.h2>

        {/* Improved CTA */}
        <div className="pt-4 md:pt-8 flex flex-col gap-2 items-center sm:flex-row sm:gap-4">
          <Link href="/signup">
            <Button
              className="rounded-md text-lg bg-blue-600 px-4 py-6"
              aria-label="Start your free trial on Learning Cortex"
            >
              Start Free Trial
            </Button>
          </Link>
        </div>
      </div>

      {/* Video Section */}
      <motion.div
        className="flex w-full md:w-1/2 items-center justify-center py-4 md:py-0"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
        variants={childVariants}
      >
        <div className="w-full max-w-md md:max-w-xl lg:max-w-2xl h-auto relative">
          {/* Background Video */}
          <video
            className="w-full h-full object-cover rounded-lg border border-grey-600"
            loop
            muted
            autoPlay // ✅ Ensure autoplay works
            ref={videoRef}
            src="/videos/cortex-background-video.webm"
            playsInline
            aria-label="Cortex AI study tool preview"
          />

          {/* Accessible Play Button */}
          <button
            aria-label="Play Learning Cortex demo video"
            className="absolute inset-0 z-10 text-grey-300/70 flex items-center justify-center "
            onClick={handlePlayButtonClick}
            type="button"
          >
            <PlaySolid className="w-12 h-12" />
          </button>

          {/*  Video Modal */}
          <AnimatePresence>
            {isModalOpen && (
              <VideoModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                videoUrl="/videos/Fall_Demo.webm"
              />
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </section>
  )
}
