"use client";

import Head from "next/head";
import dynamic from "next/dynamic";
import { motion } from "framer-motion";
import NavBar from "./_components/Navbar";
import Hero from "./_components/Hero";
import Footer from "./_components/Footer";

// Lazy-load non-critical sections for performance
const Testimonial = dynamic(() => import("./_components/Testimonial"), { ssr: false });
const ValuePropositions = dynamic(() => import("./_components/ValuePropositions"), { ssr: false });
const StudyComparison = dynamic(() => import("./_components/StudyComparison"), { ssr: false });
const StudyProcess = dynamic(() => import("./_components/StudyProcess"), { ssr: false });
const WhoItsFor = dynamic(() => import("./_components/WhoItsFor"), { ssr: false });
const FAQSection = dynamic(() => import("./_components/FAQsection"), { ssr: false });
const CallToAction = dynamic(() => import("./_components/CallToAction"), { ssr: false });

const staggerContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

export default function LandingPage() {
  return (
    <>
      {/* SEO Optimization with Head */}
      <Head>
        <title>Learning Cortex - AI-Powered Study Assistant</title>
        <meta
          name="description"
          content="Learning Cortex helps students study smarter with AI-generated study guides, flashcards, and quizzes."
        />
        <meta name="keywords" content="AI study tool, flashcards, spaced repetition, study guides" />
        <meta property="og:title" content="Learning Cortex - AI-Powered Study Assistant" />
        <meta property="og:description" content="Master your studies with AI-driven tools to boost retention and efficiency." />
        <meta property="og:image" content="https://www.learningcortex.ai/images/learning-cortex-preview.png" />
        <meta property="og:url" content="https://www.learningcortex.ai" />
        <meta name="robots" content="index, follow" />
      </Head>

      {/* Background & Layout */}
      <div className="bg-gradient-to-t from-sky-950 via-grey-900 to-base-black min-h-screen scroll-smooth">
        <nav className="relative max-w-7xl mx-auto">
          <NavBar />
        </nav>

        {/* Optimized Container */}
        <motion.div
          className="relative px-10 sm:px-4 py-8 sm:py-16 max-w-7xl mx-auto"
          variants={staggerContainer}
          initial="hidden"
          animate="show"
        >
          <Hero />
          <Testimonial />
          <ValuePropositions />
          <StudyComparison />
          <StudyProcess />
          <WhoItsFor />
          <FAQSection />
          <CallToAction />
        </motion.div>
      </div>

      <footer>
        <Footer />
      </footer>
    </>
  );
}
